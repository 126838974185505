<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Plugins &amp; Themes</h2>
          <p>
            Here you can see all the plugins and themes used on your WordPress
            site. Next to each plugin / theme you can see the current version
            number and any available updates (checked hourly).
          </p>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <div class="column is-12">
            <collapse title="Plugins">
              <site-wordpress-plugins :site-id="siteId" />
            </collapse>
          </div>

          <div class="column is-12">
            <collapse title="Themes">
              <site-wordpress-themes :site-id="siteId" />
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteWordpressPlugins",
  components: {
    "site-wordpress-plugins": () =>
      import("@shared/sites/_siteWordpressPluginsView.vue"),
    "site-wordpress-themes": () =>
      import("@shared/sites/_siteWordpressThemesView.vue")
  },
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      activeTab: 0
    };
  }
};
</script>
